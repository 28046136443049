import React from 'react';
import Header from '../../organisms/Header';
import SectionWithImage from '../../organisms/SectionWithImage';
import SectionData from '../../molecules/section-data';
import './index.scss';
import Footer from '../../organisms/Footer';
import { Helmet } from 'react-helmet-async';
import { NOT_FOUND } from '../../../constant/seo';


function NotFound() {

  return (
    <div>
      <Helmet>
        <title>{NOT_FOUND.title}</title>
        <meta name="description"
          content={`${NOT_FOUND.description}`} />
        <meta property="og:title" content={`${NOT_FOUND.title}`} />
        <meta name="twitter:title" content={`${NOT_FOUND.title}`} />
        <meta property="og:description" content={`${NOT_FOUND.description}`} />
        <meta name="twitter:description" content={`${NOT_FOUND.description}`} />
        <link rel="canonical" href={`${NOT_FOUND.canonicalLink}`} />
      </Helmet>
      <Header page='NotFound' />

      <SectionWithImage image={<img className="home1-laptop" src={require('../../atoms/images/404.webp')} alt='Fliqbook, page not found' />}>
        <SectionData
          header='Lost in cyberspace?'
          link={{
            label: 'Read More',
            to: ''
          }}
        />
      </SectionWithImage>


      <Footer page='NotFound' />
    </div>
  );
}

export default NotFound;
