import React, { Component } from 'react';

class Fb extends Component {
  render() {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
        <g clipPath='url(#clip0_2281_12976)'>
          <path
            d='M37.7923 40.0002C39.0114 40.0002 40 39.0117 40 37.7925V2.20766C40 0.988281 39.0114 0 37.7923 0H2.20766C0.988125 0 0 0.988281 0 2.20766V37.7925C0 39.0117 0.988125 40.0002 2.20766 40.0002H37.7923Z'
            fill='#395185'
          />
          <path
            d='M27.5991 40.0017V24.5116H32.7986L33.577 18.4748H27.5991V14.6205C27.5991 12.8727 28.0845 11.6816 30.5909 11.6816L33.7877 11.6802V6.28094C33.2345 6.20734 31.337 6.04297 29.1295 6.04297C24.5205 6.04297 21.3652 8.85625 21.3652 14.0228V18.4748H16.1523V24.5116H21.3652V40.0017H27.5991Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_2281_12976'>
            <rect width='40' height='40' fill='white' />
          </clipPath>
        </defs>
      </svg>
    );
  }
}

export default Fb;
