import React, { Component } from 'react';

class MapIcon extends Component {
  render() {
    return (
      <svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.5 0.306641C4.49844 0.306641 0.5 4.30508 0.5 9.30664C0.5 11.0363 1.01562 12.3723 1.91094 13.727L8.55313 23.8051C8.75469 24.1098 9.10156 24.3066 9.5 24.3066C9.89844 24.3066 10.25 24.1051 10.4469 23.8051L17.0891 13.727C17.9844 12.3723 18.5 11.0363 18.5 9.30664C18.5 4.30508 14.5016 0.306641 9.5 0.306641ZM9.5 14.3035C6.73906 14.3035 4.49844 12.0629 4.49844 9.29727C4.49844 6.53164 6.73906 4.29102 9.5 4.29102C12.2609 4.29102 14.5016 6.53164 14.5016 9.29727C14.5016 12.0629 12.2609 14.3035 9.5 14.3035ZM9.5 6.30664C7.84062 6.30664 6.5 7.64727 6.5 9.30664C6.5 10.966 7.84062 12.3066 9.5 12.3066C11.1594 12.3066 12.5 10.966 12.5 9.30664C12.5 7.64727 11.1594 6.30664 9.5 6.30664Z" fill="#BE1E2E" />
      </svg>

    );
  }
}

export default MapIcon;