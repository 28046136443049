import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { CONTACT } from '../../../constant/seo';
import ContactForm from '../../organisms/ContactForm';
import Footer from '../../organisms/Footer';
import Header from '../../organisms/Header';
import homeImg9 from '../../atoms/images/svg/home9.svg';
import './style.scss'
import SectionWithImage from '../../organisms/SectionWithImage';
import Map from '../../molecules/map/map';

class Contact extends Component {
  render() {
    return (
      <div className='contact-us'>
        <Helmet>
          <title>{CONTACT.title}</title>
          <meta name="description"
            content={`${CONTACT.description}`} />
          <meta property="og:title" content={`${CONTACT.title}`} />
          <meta name="twitter:title" content={`${CONTACT.title}`} />
          <meta property="og:description" content={`${CONTACT.description}`} />
          <meta name="twitter:description" content={`${CONTACT.description}`} />
          <link rel="canonical" href={`${CONTACT.canonicalLink}`} />
        </Helmet>
        <Header page='contact'></Header>
        <ContactForm image={<img src={homeImg9} alt='Contact Us' />}></ContactForm>
        <SectionWithImage image={<Map />}>
        <div className='contact-info'>
          <h2>Fliqbook</h2>
          <p className='contact-email'>support@fliqbook.in</p>
          Fliqbook Automation Pvt. Ltd.,<br />
          H/1/15 Arjun Apt, Nr Shanti Apt, <br />
          Nr Pragatinagar Road, Naranpura, <br />
          Ahmedabad - 380013, Gujarat, India. <br />
        </div>
        </SectionWithImage>
        <Footer page='contact'/>
      </div>
    );
  }
}

export default Contact;
