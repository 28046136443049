import React from 'react';
import List from '../../atoms/list';
import './style.scss';
import Link from '../../atoms/link';

function SectionData(props) {
  return (
    <div className={`section-data ${props.sectionClass}`}>
      <h2 className='tagLiner'> {props.tagline} </h2>
      <h1 className={`header-text`}> {props.header}</h1>
      <h2 className='subheader'> {props.subheader}</h2>
      <h4 className='description'> {props.description}</h4>
      {props.list && <List data={props.list} key={props.header}></List>}
      {/* {props.button && <Button label={props.button.label} onClick={props.button.onClick} key={props.header}></Button>} */}
      {props.link && <Link link={props.link.to} label={props.link.label} />  }
      {props.externalLink && <Link externalLink={props.externalLink.href} label={props.externalLink.label} />  }
    </div>
  );
}

export default SectionData;
