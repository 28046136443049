import React from "react";

function Check() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <path d="M25 0C11.2 0 0 11.2 0 25C0 38.8 11.2 50 25 50C38.8 50 50 38.8 50 25C50 11.2 38.8 0 25 0ZM18.225 35.725L9.25 26.75C8.275 25.775 8.275 24.2 9.25 23.225C10.225 22.25 11.8 22.25 12.775 23.225L20 30.425L37.2 13.225C38.175 12.25 39.75 12.25 40.725 13.225C41.7 14.2 41.7 15.775 40.725 16.75L21.75 35.725C20.8 36.7 19.2 36.7 18.225 35.725Z" fill="#474F64" />
        </svg>
    );
}

export default Check;
