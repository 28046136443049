import "./style.scss";

const Loader = () => {
  return (
    <div className="overlay" data-cy="overlay">
      <div id="wave">
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>
    </div>
  );
}

export default Loader;