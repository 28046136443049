import React from 'react';

function Logo(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 74 56' fill='none'>
      <path
        d='M55.3359 0C48.8359 0 41.8359 1.33333 37.0026 5C32.1693 1.33333 25.1693 0 18.6693 0C13.8359 0 8.7026 0.733333 4.4026 2.63333C1.96927 3.73333 0.335938 6.1 0.335938 8.8V46.4C0.335938 50.7333 4.4026 53.9333 8.6026 52.8667C11.8693 52.0333 15.3359 51.6667 18.6693 51.6667C23.8693 51.6667 29.4026 52.5333 33.8693 54.7333C35.8693 55.7333 38.1359 55.7333 40.1026 54.7333C44.5693 52.5 50.1026 51.6667 55.3026 51.6667C58.6359 51.6667 62.1026 52.0333 65.3693 52.8667C69.5693 53.9667 73.6359 50.7667 73.6359 46.4V8.8C73.6359 6.1 72.0026 3.73333 69.5693 2.63333C65.3026 0.733333 60.1693 0 55.3359 0ZM67.0026 42.4333C67.0026 44.5333 65.0693 46.0667 63.0026 45.7C60.5026 45.2333 57.9026 45.0333 55.3359 45.0333C49.6693 45.0333 41.5026 47.2 37.0026 50.0333V11.6667C41.5026 8.83333 49.6693 6.66667 55.3359 6.66667C58.4026 6.66667 61.4359 6.96667 64.3359 7.6C65.8693 7.93333 67.0026 9.3 67.0026 10.8667V42.4333Z'
        fill={props.reverse ? '#fff' : '#474F64'}
      />
      <path
        d='M43.6009 21.6982C42.5342 21.6982 41.5676 21.0315 41.2342 19.9649C40.8009 18.6649 41.5342 17.2315 42.8342 16.8315C47.9676 15.1649 54.6009 14.6315 60.7009 15.3315C62.0676 15.4982 63.0676 16.7315 62.9009 18.0982C62.7342 19.4649 61.5009 20.4649 60.1342 20.2982C54.7342 19.6649 48.8342 20.1649 44.3676 21.5982C44.1009 21.6315 43.8342 21.6982 43.6009 21.6982Z'
        fill={props.reverse ? '#fff' : '#474F64'}
      />
      <path
        d='M43.6009 30.5654C42.5342 30.5654 41.5676 29.8987 41.2342 28.8321C40.8009 27.5321 41.5342 26.0987 42.8342 25.6987C47.9342 24.0321 54.6009 23.4987 60.7009 24.1987C62.0676 24.3654 63.0676 25.5987 62.9009 26.9654C62.7342 28.3321 61.5009 29.3321 60.1342 29.1654C54.7342 28.5321 48.8342 29.0321 44.3676 30.4654C44.1009 30.5321 43.8342 30.5654 43.6009 30.5654Z'
        fill={props.reverse ? '#fff' : '#474F64'}
      />
      <path
        d='M43.6009 39.4326C42.5342 39.4326 41.5676 38.7659 41.2342 37.6993C40.8009 36.3993 41.5342 34.9659 42.8342 34.5659C47.9342 32.8993 54.6009 32.3659 60.7009 33.0659C62.0676 33.2326 63.0676 34.4659 62.9009 35.8326C62.7342 37.1993 61.5009 38.1659 60.1342 38.0326C54.7342 37.3993 48.8342 37.8993 44.3676 39.3326C44.1009 39.3993 43.8342 39.4326 43.6009 39.4326Z'
        fill={props.reverse ? '#fff' : '#474F64'}
      />
    </svg>
  );
}

export default Logo;
