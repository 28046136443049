import React from 'react';
import './style.scss';
import { useContext } from 'react';
import { ApiContext } from '../../../context/api';

function Button(props) {
  const { analytics } = useContext(ApiContext);

  const mouseEnterEvent = () => {
    analytics.logData({ event: 'button_mouse_entered', data: { label: props.label } });
  }

  const mouseLeaveEvent = () => {
    analytics.logData({ event: 'button_mouse_leave', data: { label: props.label } });
  }

  const onClickEvent = (e) => {
    analytics.logData({ event: 'button_clicked', data: { label: props.label } });

    if (props.onClick) {
      props.onClick(e);
    }
  }


  return <button type={props.type ?? 'button'} className='button' onMouseEnter={mouseEnterEvent} onMouseLeave={mouseLeaveEvent} onClick={onClickEvent}>{props.label}</button>;
}

export default Button;
