import React, { Component } from 'react';

class Book extends Component {
  render() {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80' fill='none'>
        <path
          d='M10.0013 20.0013C8.16797 20.0013 6.66797 21.5013 6.66797 23.3346V66.668C6.66797 70.3346 9.66797 73.3346 13.3346 73.3346H56.668C58.5013 73.3346 60.0013 71.8346 60.0013 70.0013C60.0013 68.168 58.5013 66.668 56.668 66.668H16.668C14.8346 66.668 13.3346 65.168 13.3346 63.3346V23.3346C13.3346 21.5013 11.8346 20.0013 10.0013 20.0013ZM66.668 6.66797H26.668C23.0013 6.66797 20.0013 9.66797 20.0013 13.3346V53.3346C20.0013 57.0013 23.0013 60.0013 26.668 60.0013H66.668C70.3346 60.0013 73.3346 57.0013 73.3346 53.3346V13.3346C73.3346 9.66797 70.3346 6.66797 66.668 6.66797ZM60.0013 36.668H33.3346C31.5013 36.668 30.0013 35.168 30.0013 33.3346C30.0013 31.5013 31.5013 30.0013 33.3346 30.0013H60.0013C61.8346 30.0013 63.3346 31.5013 63.3346 33.3346C63.3346 35.168 61.8346 36.668 60.0013 36.668ZM46.668 50.0013H33.3346C31.5013 50.0013 30.0013 48.5013 30.0013 46.668C30.0013 44.8346 31.5013 43.3346 33.3346 43.3346H46.668C48.5013 43.3346 50.0013 44.8346 50.0013 46.668C50.0013 48.5013 48.5013 50.0013 46.668 50.0013ZM60.0013 23.3346H33.3346C31.5013 23.3346 30.0013 21.8346 30.0013 20.0013C30.0013 18.168 31.5013 16.668 33.3346 16.668H60.0013C61.8346 16.668 63.3346 18.168 63.3346 20.0013C63.3346 21.8346 61.8346 23.3346 60.0013 23.3346Z'
          fill='#474F64'
        />
      </svg>
    );
  }
}

export default Book;
