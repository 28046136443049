import React, { useEffect, useContext } from 'react';
import Header from '../../organisms/Header';
import SectionWithImage from '../../organisms/SectionWithImage';
import SectionData from '../../molecules/section-data';
import SectionWithList from '../../organisms/SectionWithList';
import Book from '../../atoms/svg/book';
import Laptop from '../../atoms/svg/laptop';
import Logo from '../../atoms/svg/logo';
import './index.scss';
import ContactForm from '../../organisms/ContactForm';
import Footer from '../../organisms/Footer';
import Text from '../../atoms/text';
import { StateContext } from '../../../context/State';
import { HOME2 } from '../../../constant/seo';
import { Helmet } from 'react-helmet-async';
import homeImg1 from '../../atoms/images/svg/home1.svg';
import homeImg2 from '../../atoms/images/svg/home2.svg';
import homeImg3 from '../../atoms/images/svg/home3.svg';
import homeImg4 from '../../atoms/images/svg/home4.svg';
import homeImg5 from '../../atoms/images/svg/home5.svg';
import homeImg6 from '../../atoms/images/svg/home6.svg';
import homeImg7 from '../../atoms/images/svg/home7.svg';
import homeImg8 from '../../atoms/images/svg/home8.svg';
import homeImg9 from '../../atoms/images/svg/home9.svg';


function Home2() {

  const { updateVariant } = useContext(StateContext);

  useEffect(() => {
    updateVariant(2);
  }, [updateVariant])

  return (
    <div>
      <Helmet>
        <title>{HOME2.title}</title>
        <meta name="description"
          content={`${HOME2.description}`} />
        <meta property="og:title" content={`${HOME2.title}`} />
        <meta name="twitter:title" content={`${HOME2.title}`} />
        <meta property="og:description" content={`${HOME2.description}`} />
        <meta name="twitter:description" content={`${HOME2.description}`} />
        <link rel="canonical" href={`${HOME2.canonicalLink}`} />
      </Helmet>
      <Header page='home2' />

      <SectionWithImage image={<img className="home1-laptop" src={homeImg1} alt='MAKE GST FILING FAST. SIMPLE. EASY.' />}>
        {/* style={{ marginTop: '-80px' }}  */}
        <SectionData
          tagline='MAKE AMAZON ACCOUNTING FAST. SIMPLE. EASY.'
          header='India’s first and only dedicated software for bookkeeping, accounting & GST filing for Amazon Sellers.'
          subheader='Perform accounting and GST filing using your Amazon MTR in just a few clicks. Accurate, fast and easy to use'
          externalLink={{
            label: 'SIGNUP FOR A FREE TRIAL',
            href: 'https://app.fliqbook.in/signup?p=1&s=0'
          }}
        />
      </SectionWithImage>

      <SectionWithList
        list={[
          { icon: <Logo />, title: 'Book Keeping', description: 'Hassle free book keeping using your Amazon MTR file' },
          { icon: <Laptop />, title: 'Accounting', description: 'Automated accounting in a few minutes' },
          { icon: <Book />, title: 'GST Filing', description: 'Quick GST filing in a few clicks' },
        ]}
      ></SectionWithList>

      <SectionWithImage image={<img src={homeImg2} alt='Amazon MTR to GSTR1 in just a few clicks' />} rtl={true}>
        <SectionData
          header='Amazon MTR to GSTR1 in just a few clicks'
          description='Convert your Amazon MTR into GSTR1-ready .csv or .json file quickly and accurately. Customized for both the monthly as well as the quarterly filers!'
        /* link={{
           label: 'Read More',
           to: '/features/1'
         }}*/
        />
      </SectionWithImage>

      <SectionWithImage image={<img src={homeImg3} alt='Amazon MTR to Sales & Tax Analytics' />} backgroundColor='#E6F5EA'>
        <SectionData
          header='Amazon MTR to Sales & Tax Analytics'
          description='fliqbook converts your Amazon MTR into beautiful charts and summary tables to get a clear visualization of your monthly sales and taxes'
        /* link={{
           label: 'Read More',
           to: '/features/2'
         }}*/
        />
      </SectionWithImage>

      <SectionWithImage image={<img src={homeImg4} alt='Automated Income Statement & Profit Calculation' />} rtl={true}>
        <SectionData
          header='Automated Income Statement & Profit Calculation'
          description='Fliqbook calculates profits automatically using your Amazon MTR and linking it with your purchase and expense data. It can even generate income statement quickly and accurately.'
        /* link={{
           label: 'Read More',
           to: '/features/3'
         }}*/
        />
      </SectionWithImage>

      <SectionWithImage image={<img src={homeImg5} alt='Order Settlement Reconciliation' />} backgroundColor='#E6F5EA'>
        <SectionData
          header='Order Settlement Reconciliation'
          description='Check whether the Amazon has missed to settle any of your monthly sales orders by comparing Amazon payments reports with Amazon MTR in just 1 click! Turning your hours of husstle in a 2 minutes job!!'
        /* link={{
           label: 'Read More',
           to: '/features/3'
         }}*/
        />
      </SectionWithImage>

      <SectionWithImage image={<img src={homeImg6} alt='TDS Reconciliation' />} rtl={true}>
        <SectionData
          header='TDS Reconciliation'
          description='Fliqbook automatically fetches and calculates the TDS from the settled orders. Compare this TDS value with your FORM 16A and FORM 26AS to lookout for any probable mismatch!'
        /* link={{
           label: 'Read More',
           to: '/features/3'
         }}*/
        />
      </SectionWithImage>

      <SectionWithImage image={<img src={homeImg7} alt='Amazon MTR to Tally' />} backgroundColor='#E6F5EA'>
        <SectionData
          header='Amazon MTR to Tally'
          description='Already using Tally for your business? Don’t worry. We’ve got you covered! You can now convert your Amazon MTR into formats that can be easily imported inside Tally.'
        /* link={{
           label: 'Read More',
           to: '/features/2'
         }}*/
        />
      </SectionWithImage>

      <SectionWithImage image={<img src={homeImg8} alt='In us you trust. your data is private and secured' />} >
        <SectionData
          header='In us you trust. your data is private and secured'
          description='We use Firebase to store our data which is secured under ISO and SOC Compliance!'
          link={{
            label: 'Read More',
            to: '/privacy'
          }}
        />
      </SectionWithImage>

      <Text text='In Short, fliqbook helps you to shift your focus from hustling with your financials to growing your Amazon business.' />

      <ContactForm image={<img src={homeImg9} alt='Contact Us' />}></ContactForm>

      <Footer page='home2' />
    </div>
  );
}

export default Home2;
