import React from "react";

function Check() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
      <path
        d='M16.0013 2.66797C8.6413 2.66797 2.66797 8.6413 2.66797 16.0013C2.66797 23.3613 8.6413 29.3346 16.0013 29.3346C23.3613 29.3346 29.3346 23.3613 29.3346 16.0013C29.3346 8.6413 23.3613 2.66797 16.0013 2.66797ZM12.388 21.7213L7.6013 16.9346C7.0813 16.4146 7.0813 15.5746 7.6013 15.0546C8.1213 14.5346 8.9613 14.5346 9.4813 15.0546L13.3346 18.8946L22.508 9.7213C23.028 9.2013 23.868 9.2013 24.388 9.7213C24.908 10.2413 24.908 11.0813 24.388 11.6013L14.268 21.7213C13.7613 22.2413 12.908 22.2413 12.388 21.7213Z'
        fill='#474F64'
      />
    </svg>
  );
}

export default Check;
