import React from 'react';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { StateContext } from '../../../context/State';
import Fb from '../../atoms/svg/fb';
import Ig from '../../atoms/svg/ig';
import In from '../../atoms/svg/in';
import Tw from '../../atoms/svg/tw';
import './style.scss';

function Footer(props) {

  const { variant } = useContext(StateContext);

  return (
    <div className='footer'>
      <div className='ft-main'>
        <div className='ft-main-item'>
          <span className='ft-title'>Quick Links</span>
          <ul>
            {!props.page &&
              <li>
                <NavLink to='/home1' activeclassname='active'>
                  Home
                </NavLink>
              </li>
            }
            {(variant === 2) &&
              <li>
                <NavLink to='/pricing2' activeclassname='active'>
                  Pricing
                </NavLink>
              </li>
            }
            {!props.page &&
              <li>
                <NavLink to='/features' activeclassname='active'>
                  Features
                </NavLink>
              </li>
            }  
              <li>
                <NavLink to='/contact' activeclassname='active'>
                  Contact Us
                </NavLink>
              </li>
          
            <li>
              <a href='https://app.fliqbook.in/signin' activeclassname='active'>
                Login
              </a>
            </li>

          </ul>
        </div>

        <div className='ft-main-item'>
          <span className='ft-title'>Legal</span>
          <ul>
            <li>
              <NavLink to='/privacy' activeclassname='active'>
                Privacy Policy
              </NavLink>
            </li>
            <li>
              <NavLink to='/terms_of_use' activeclassname='active'>
                Terms of Use
              </NavLink>
            </li>
            <li>
              <NavLink to='/cancellation_refund_policy' activeclassname='active'>
                Cancellation and Refund Policy
              </NavLink>
            </li>
          </ul>
        </div>

        <div className='ft-main-item'>
          <span className='ft-title'>Email Support</span>
          <ul>
            <li>
              <a href='mailto:support@fliqbook.in'>
                support@fliqbook.in
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='ft-social'>

        <ul className='ft-social-list'>
          <li>
            <span className='ft-title'>Connect with us:</span>
          </li>
          <li>
            <a href='https://www.facebook.com/profile.php?viewas=100000686899395&id=100088958455700'>
              <Fb />
            </a>
          </li>
          <li>
            <a href='https://twitter.com/fliqbook'>
              <Tw />
            </a>
          </li>
          <li>
            <a href='https://www.linkedin.com/company/fliqbook/'>
              <In />
            </a>
          </li>
          <li>
            <a href='https://www.instagram.com/fliqbook/'>
              <Ig />
            </a>
          </li>
        </ul>
      </div>
      <div className='ft-legal'>
        <ul className='ft-legal-list'>
          <li>&copy; Copyright 2023 </li>
          <li>
            <a href='https://fliqbook.in'>
              Fliqbook Automation Pvt. Ltd.
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
