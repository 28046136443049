import React from 'react';
import { Helmet } from 'react-helmet-async';
import { TERMS } from '../../../constant/seo';
import Footer from '../../organisms/Footer';
import Header from '../../organisms/Header';
import './style.scss';

const CancellationRefund = () => (
  <div className='cancellation_refund'>
    <Helmet>
      <title>{TERMS.title}</title>
      <meta name="description"
        content={`${TERMS.description}`} />
      <meta property="og:title" content={`${TERMS.title}`} />
      <meta name="twitter:title" content={`${TERMS.title}`} />
      <meta property="og:description" content={`${TERMS.description}`} />
      <meta name="twitter:description" content={`${TERMS.description}`} />
      <link rel="canonical" href={`${TERMS.canonicalLink}`} />
    </Helmet>
    <Header page='privacy' />
    <div className='outer-box'>
    <h2>Cancellation and Refund Policy</h2>
      <p>This Cancellation and Refund Policy shall apply to any cancellation of subscription of the fliqbook Products and applicable refunds. fliqbook shall not entertain any refund requests if cancellation of subscription is in violation of this policy or its Terms of Use or if fliqbook terminates the User account for violation of its Terms of Use.</p>
      
      <p>This Cancellation and Refund Policy is part of and incorporated within, and is to be read along with the Terms of Use https://fliqbook.in/terms_of_use. The terms capitalised in this policy and not defined herein shall have the meaning assigned to them in the Terms of Use.</p>
<ol>
      <li>
        <p>fliqbook permits cancellation of subscription if a User is not satisfied with the product or service. Cancellation of a first-time subscription is eligible for refund only within 7 days from the date of purchase of subscription (“Purchase Date”).</p>
      </li>
      <li>
        <p>A User can only avail refund once. Renewal of subscription after the expiration of its term shall not entitle a User to avail refund.</p>
      </li>
      <li>
        <p>The date on invoice for subscription shall be considered as final and conclusive for determining the Purchase Date.</p>
      </li>
      <li>
        <p>fliqbook may permit Users to upgrade their subscription by purchasing a higher priced subscription. Where a User upgrades its subscription, the refund shall be governed by the Purchase Date and not from the date of upgrade of subscription (“Upgrade Date”). To clarify, a User who avails an upgrade is eligible for refund upon cancellation of the subscription within 7 days from the Purchase Date only.</p>
      </li>
      <li>
        <p>Under no circumstance shall a User be entitled to a refund upon cancellation of subscription after 7 days from the Purchase Date.</p>
      </li>
      <li>
        <p>All requests for cancellation of subscription and refund should be raised only via the customer support helpline provided by fliqbook. Cancellation and refund requests raised through any other mode of communication will not be considered for refund.</p>
      </li>
      <li>
        <p>fliqbook has arrangements with banks, affiliates, payment gateways, payment aggregators and other financial service providers for processing refunds (“Service Providers”). fliqbook endeavours to ensure that eligible refunds are processed within 20 working days of your request for cancellation of subscription. However, this timeline is indicative and processing of refunds is subject to the time taken by the Service Providers.</p>
      </li>
      <li>
        <p>The User agrees to provide any information required to facilitate refunds including but not limited to bank account details, bank branch names, UPI addresses, IFSC codes.</p>
      </li>
      <li>
        <p>The User acknowledges and agrees that remittance of refund to the User is subject to the charges that may be levied by the Service Providers for such remittance. Such charges shall be deducted from the refund claimed by the User.</p>
      </li>    
</ol>

    </div>
    <Footer page='privacy' />
  </div>
);

export default CancellationRefund;
