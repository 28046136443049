import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { ApiContext } from '../../../context/api';
import Button from '../../atoms/button';
import './style.scss';

function ContactForm(props) {
  const [email, setEmail] = useState('');
  const [query, setQuery] = useState('');

  const { support } = useContext(ApiContext);

  const submitContactForm = (event) => {
    console.log('contact form submit clicked');
    event.preventDefault();
    support.createSupportRequest({ email, query }).then(isSucceed => {
      if (isSucceed) {
        setEmail('');
        setQuery('');
        alert('Your query has been submitted...!');
      } else {
        setEmail('');
        setQuery('');
        alert('Something went wrong, please try again later...!');
      }
    });
  }

  return (
    <div className="contact">
      <div className='contact-form section-with-image'>
        <div className='section-left'>{props.image}</div>
        <form className='section-right' onSubmit={submitContactForm}>
          <h2 className='title'> YOUR TIME IS VALUABLE </h2>
          <span className='subtitle'> Get answers quick</span>
          <span className='content'>
            <div className='email'>
              <div>Email</div>
              <input type='email' name='email' placeholder='example@email.com' required value={email} onChange={(e) => { setEmail(e.target.value) }} />
            </div>
            <div className='query'>
              <div>Please tell us your query</div>
              <textarea name='query' rows={5} placeholder='Type your message or query' required value={query} onChange={(e) => { setQuery(e.target.value) }} />
            </div>
          </span>
          <div className='button-container'>
            <Button
              label='SUBMIT'
              type='submit'
            ></Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
