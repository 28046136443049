import { Routes, Route } from 'react-router-dom';
import React, { Component } from 'react';
import Home1 from './components/pages/home1';
import Home2 from './components/pages/home2';
import Pricing2 from './components/pages/pricing2';
import Contact from './components/pages/contact';
import Privacy from './components/pages/privacy';
import TermsOfUse from './components/pages/terms_of_use';
import CancellationRefund from './components/pages/cancellation_refund_policy';
import { StateProvider } from './context/State';
import NotFound from './components/pages/not_found';
import { HelmetProvider } from 'react-helmet-async';
import { ApiProvider } from './context/api';

class App extends Component {

  render() {
    return (
      <HelmetProvider>
        <ApiProvider>
          <StateProvider>
            <Routes>
              <Route path='/contact' element={<Contact />} />
              <Route path='/pricing2' element={<Pricing2 />} />
              <Route path='/privacy' element={<Privacy />} />
              <Route path='/terms_of_use' element={<TermsOfUse />} />
              <Route path='/cancellation_refund_policy' element={<CancellationRefund />} />
              <Route path='/home1' element={<Home1 />} />
              <Route path='/' element={<Home2 />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="*" element={<Home2 />} />
            </Routes>
          </StateProvider>
        </ApiProvider>
      </HelmetProvider>
    );
  }
}

export default App;
