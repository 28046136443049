import Close from "../../atoms/svg/close"
import Modal from "../../template/modal"
import './style.scss'
import { useContext, useEffect } from 'react';
import { ApiContext } from '../../../context/api';
import Link from "../../atoms/link";

const ConfirmModal = ({ isOpened, closeModal, isAnnually }) => {
  const { analytics } = useContext(ApiContext);

  const closeModalEvent = (e) => {
    analytics.logData({ event: 'close_modal_clicked', data: { modal: 'Basic Plan Modal' } })
    closeModal(e);
  }

  useEffect(() => {
    if (isOpened) {
      analytics.logData({ event: 'modal_opened', data: { modal: 'Basic Plan Modal' } })
    }
  }, [isOpened, analytics])

  return (
    <Modal isOpened={isOpened}>
      <div className="modal-header">
        <button onClick={closeModalEvent}><Close /></button>
      </div>
      <div className="modal-body">
        <div>Thanks for choosing the <b>“Basic Plan”!!</b></div>
        <div>You can try it <b>free for a month</b></div>
      </div>
      <div className="modal-footer">
        <Link label={`LET'S GET STARTED`} externalLink={`https://app.fliqbook.in/signup?p=1&s=${isAnnually ? 1 : 0}`} />
      </div>
    </Modal>
  )
}

export default ConfirmModal;