import React from 'react';
import './style.scss';
import { useContext } from 'react';
import { ApiContext } from '../../../context/api';
import { NavLink } from 'react-router-dom';

function Link(props) {
  const { analytics } = useContext(ApiContext);

  const mouseEnterEvent = () => {
    analytics.logData({ event: 'button_mouse_entered', data: { label: props.label } });
  }

  const mouseLeaveEvent = () => {
    analytics.logData({ event: 'button_mouse_leave', data: { label: props.label } });
  }

  const onClickEvent = (e) => {
    analytics.logData({ event: 'button_clicked', data: { label: props.label } });
  }


  if (props.link) {
    return <NavLink className='link' to={props.link} onMouseEnter={mouseEnterEvent} onMouseLeave={mouseLeaveEvent} onClick={onClickEvent}>{props.label}</NavLink>;
  }

  if (props.externalLink) {
    return <a href={props.externalLink} className='link' onMouseEnter={mouseEnterEvent} onMouseLeave={mouseLeaveEvent} onClick={onClickEvent}>{props.label}</a>
  }

  return;
}

export default Link;
