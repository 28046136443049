import React from 'react';
import './style.scss';

function SectionWithList(props) {
  return (
    <div className='section section-with-list'>
      {props.list.map(data => 
        <div className='list-item' key={data.title}>
          <div className='list-icon'>{data.icon}</div>
          <h2 className='list-title'>{data.title}</h2>
          <h3 className='list-description'>{data.description}</h3>
        </div>
      )}
    </div>
  );
}

export default SectionWithList;
