import { createContext, memo, useState } from "react";

export const StateContext = createContext({
  variant: +localStorage.getItem('landing_variant') || 2
});

export const StateProvider = memo(({ children }) => {
  const [variant, setVariant] = useState(+localStorage.getItem('landing_variant') || 2);

  const updateVariant = (newVariant) => {
    localStorage.setItem('landing_variant', newVariant);

    if (variant !== newVariant) {
      setVariant(newVariant);
    }
  }

  const value = { variant, updateVariant };

  return <StateContext.Provider value={value}>
    {children}
  </StateContext.Provider>
});