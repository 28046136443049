import { createContext, memo } from "react";
import { initializeApp } from "firebase/app";
// import { connectAuthEmulator, initializeAuth, signOut } from "firebase/auth";
import { getFirestore, collection, addDoc, serverTimestamp } from "firebase/firestore/lite";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { firebaseConfig } from "../helpers/firebase";
import { useState } from "react";
import Loader from "../components/molecules/loader/loader";

export const ApiContext = createContext({
  analytics: {
    logData: () => { }
  },
  support: {
    createSupportRequest: () => { }
  }
});

export const ApiProvider = memo(({ children }) => {
  // consoleLog('rendering api context provider');
  const [isLoading, setIsLoading] = useState([])
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  // const perf = 
  getPerformance(app);
  const analytics = getAnalytics(app);


  // this method is created so multiple loaders can coexist without race condition
  const updateIsLoading = (value, debug) => {
    const loading = [...isLoading];
    if (value) {
      loading.push(debug);
    } else {
      loading.pop();
    }

    setIsLoading([...loading]);
  }

  const logData = ({ event, data }) => {
    if (!data) {
      logEvent(analytics, event);
    } else {
      logEvent(analytics, event, { ...data });
    }
  };


  const createSupportRequest = async ({ query = '', email = '' }) => {
    try {
      updateIsLoading(true, 'createSupportRequest');
      const collectionRef = collection(db, 'support');
      await addDoc(collectionRef, {
        email,
        query,
        createdAt: serverTimestamp()
      });
      updateIsLoading(false, 'createSupportRequest: success');

      return true;
    } catch (error) {
      console.warn('createSupportRequest error: ', error.code, error.message);
      updateIsLoading(false, 'createSupportRequest: failure');

      return false;
    }
  }

  const value = {
    analytics: {
      logData
    },
    support: {
      createSupportRequest
    }
  };

  return <ApiContext.Provider value={value}>
    {isLoading.length ? <Loader /> : ''}
    {children}
  </ApiContext.Provider>
});