import React, { Component } from 'react';

class Tw extends Component {
  render() {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='49' height='40' viewBox='0 0 49 40' fill='none'>
        <path
          d='M48.9991 4.86974C47.1644 5.68252 45.2184 6.21603 43.2257 6.45252C45.3013 5.2085 46.895 3.23855 47.6454 0.89137C45.6723 2.06225 43.5136 2.88721 41.2625 3.3306C39.4288 1.37711 36.8165 0.15625 33.9252 0.15625C28.3739 0.15625 23.873 4.65692 23.873 10.2079C23.873 10.9959 23.962 11.7629 24.1333 12.4988C15.7792 12.0795 8.37252 8.07778 3.41462 1.99625C2.54955 3.48084 2.05386 5.20773 2.05386 7.04965C2.05386 10.5371 3.8286 13.6137 6.52563 15.4163C4.92936 15.3664 3.36821 14.9352 1.97252 14.1589C1.97194 14.201 1.97194 14.2431 1.97194 14.2854C1.97194 19.1557 5.43682 23.2185 10.0351 24.1419C8.55488 24.5445 7.00224 24.6034 5.49577 24.3141C6.77482 28.3076 10.4872 31.2137 14.8856 31.295C11.4454 33.9909 7.11108 35.598 2.4018 35.598C1.59032 35.598 0.790318 35.5503 0.00390625 35.4575C4.45233 38.3095 9.73596 39.9737 15.4125 39.9737C33.9019 39.9737 44.0123 24.6567 44.0123 11.3737C44.0123 10.9377 44.0028 10.5042 43.9832 10.0732C45.9511 8.65057 47.6497 6.88853 48.9991 4.86974Z'
          fill='#55ACEE'
        />
      </svg>
    );
  }
}

export default Tw;
