import React, { Component } from 'react';

class Laptop extends Component {
  render() {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80' fill='none'>
        <path
          d='M76.6667 60H73.3333V16.6667C73.3333 13 70.3333 10 66.6667 10H13.3333C9.66667 10 6.66667 13 6.66667 16.6667V60H3.33333C1.5 60 0 61.5 0 63.3333C0 65.1667 1.5 66.6667 3.33333 66.6667H76.6667C78.5 66.6667 80 65.1667 80 63.3333C80 61.5 78.5 60 76.6667 60ZM45 60H35C34.0667 60 33.3333 59.2667 33.3333 58.3333C33.3333 57.4 34.0667 56.6667 35 56.6667H45C45.9333 56.6667 46.6667 57.4 46.6667 58.3333C46.6667 59.2667 45.9333 60 45 60ZM66.6667 50H13.3333V20C13.3333 18.1667 14.8333 16.6667 16.6667 16.6667H63.3333C65.1667 16.6667 66.6667 18.1667 66.6667 20V50Z'
          fill='#474F64'
        />
      </svg>
    );
  }
}

export default Laptop;
