import MapIcon from "../../atoms/svg/mapIcon";

const AnyReactComponent = ({ text }) => (
  <div style={{
    color: '#BE1E2E',
    padding: '15px 10px',
    display: 'inline-flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    left: '68%',
    top: '48%',
    fontSize: '12px'

  }}>
    <MapIcon />
    {text}
  </div>
);


function Map() {

  return (
    <a style={{ height: '200px', width: '569px', position: 'relative' }} href="https://www.google.com/maps/@23.06471,72.555592,19z?hl=en-IN&entry=ttu">
      <AnyReactComponent text="Fliqbook Automation Pvt Ltd" />

      <iframe width="569" height="200"
        style={{ border: 0, pointerEvents: 'none' }}
        loading="lazy"
        allowFullScreen={false}
        src="https://www.google.com/maps/embed/v1/view?key=AIzaSyCthp98onBQ_3kuWxLxGyVLY_L_oibfJZ8&zoom=19&center=23.0647,72.5556"
        title="Google map Iframe"
        disableDefaultUI
      ></iframe>
    </a>
  );
}

export default Map;