import React from 'react';
import Button from '../../atoms/button';
import Stars from '../../atoms/svg/stars';
import Check from '../../atoms/svg/check';
import Footer from '../../organisms/Footer';
import Header from '../../organisms/Header';
import './style.scss';
import { useState } from 'react';
import ConfirmModal from '../../molecules/confirm-modal';
import { Helmet } from 'react-helmet-async';
import { PRICING_PAGE } from '../../../constant/seo';
import Link from '../../atoms/link';


function Pricing2() {
  const [isAnnually, setIsAnnually] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Helmet>
        <title>{PRICING_PAGE.title}</title>
        <meta name="description"
          content={`${PRICING_PAGE.description}`} />
        <meta property="og:title" content={`${PRICING_PAGE.title}`} />
        <meta name="twitter:title" content={`${PRICING_PAGE.title}`} />
        <meta property="og:description" content={`${PRICING_PAGE.description}`} />
        <meta name="twitter:description" content={`${PRICING_PAGE.description}`} />
        <link rel="canonical" href={`${PRICING_PAGE.canonicalLink}`} />
      </Helmet>
      <ConfirmModal isOpened={isOpened} closeModal={() => { setIsOpened(false) }} isAnnually={isAnnually} />
      <div className='pricing2'>
        <Header page="pricing2" />
        <div className='pre-text'>
          <div className='header-group'>
            <Stars />
            <div className='title'>We have kept the most affordable price for any accounting software, only for you </div>
          </div>

        </div>
        <div className='table-wrapper'>
          <table className='pricing-table'>
            <thead>
              <tr>
                <th style={{ width: 200 }}>Features</th>
                <th>
                  <div className="feature-style">
                    <h2 className='free-plan'>FREE</h2>
                    <h4>For all users</h4>
                    <h3 className='free-price'>₹ 0 </h3>
                    <h4>For sales analytics & GST preparation only!</h4>
                    <Link externalLink='https://app.fliqbook.in/signup?p=0' label='GET STARTED' />
                  </div>
                </th>
                <th>
                  <div className="feature-style">
                    <div><span className={`billing-type ${!isAnnually && 'active'}`} onClick={() => { setIsAnnually(false) }}>Quarterly Billing</span><span className={`billing-type ${isAnnually && 'active'}`} onClick={() => { setIsAnnually(true) }}>Annual Billing</span></div>
                    <h2>Basic</h2>
                    <h4>Best for Startups</h4>
                    <h3><span className='old-price'>₹ {isAnnually ? 3599 : 999}</span> </h3>
                    <h3>₹ {isAnnually ? 1499 : 499} </h3>
                    <h4><span style={{ color: '#3eae5b' }}>Launch Offer!</span></h4>
                    <Button type="button" onClick={() => { setIsOpened(true) }} label='GET STARTED'></Button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Free Trial</td>
                <td>Life Time</td>
                <td>First Two Months</td>
              </tr>
              <tr>
                <td>Amazon MTR to GSTR1 csv </td>
                <td><Check /></td>
                <td><Check /></td>
              </tr>
              <tr>
                <td>Amazon MTR to JSON File for easy filing of GSTR1 on GST portal</td>
                <td><Check /></td>
                <td><Check /></td>
              </tr>
              <tr>
                <td>Sales and Tax Analytics</td>
                <td><Check /></td>
                <td><Check /></td>
              </tr>
              <tr>
                <td>Order Settlement Reconciliation</td>
                <td><Check /></td>
                <td><Check /></td>
              </tr>
              <tr>
                <td>Data storage</td>
                <td>Upto 2 Quarters Only</td>
                <td>Upto 3 Financial Years</td>
              </tr>
              <tr>
                <td>Vendors & Purchase orders</td>
                <td>-</td>
                <td><Check /></td>
              </tr>
              <tr>
                <td>Expenses & recurring expenses</td>
                <td>-</td>
                <td><Check /></td>
              </tr>
              <tr>
                <td>Automated Financial Statements</td>
                <td></td>
                <td><Check /></td>
              </tr>
              <tr>
                <td>Amazon MTR to Tally</td>
                <td>-</td>
                <td><Check /></td>
              </tr>
              <tr>
                <td>Users</td>
                <td>1</td>
                <td>1</td>
              </tr>
              <tr>
                <td>Support</td>
                <td>-</td>
                <td>email</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td><Button label='GET STARTED' onClick={() => { setIsOpened(true) }} ></Button></td>
              </tr>
            </tfoot>
          </table>
        </div>
        <Footer page="pricing2" />
      </div></>
  );
}

export default Pricing2;
