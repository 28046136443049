export const PRICING_PAGE = {
  title: "Fliqbook - Amazon seller accounting | Amazon fba bookkeeping for Amazon sellers | Pricing page",
  description: "India's First And Only Dedicated Software For Bookkeeping, Accounting & GST Filing For Amazon Sellers.\n We have kept the most affordable price for any accounting software, only for you",
  canonicalLink: "https://fliqbook.in/pricing2",
}

export const HOME2 = {
  title: "Fliqbook - Amazon seller accounting | Amazon fba bookkeeping for Amazon sellers",
  description: "India's First And Only Dedicated Software For Bookkeeping, Accounting & GST Filing For Amazon Sellers.\n Launching offer: one month free trial",
  canonicalLink: "https://fliqbook.in/",
}

export const CONTACT = {
  title: "Fliqbook - Amazon seller accounting | Amazon fba bookkeeping for Amazon sellers | Contact us",
  description: "India's First And Only Dedicated Software For Bookkeeping, Accounting & GST Filing For Amazon Sellers.\n YOUR TIME IS VALUABLE FOR US. Get you query resolved quickly.",
  canonicalLink: "https://fliqbook.in/contact",
}

export const FEATURES = {
  title: "Fliqbook - Amazon seller accounting | Amazon fba bookkeeping for Amazon sellers",
  description: "India's First And Only Dedicated Software For Bookkeeping, Accounting & GST Filing For Amazon Sellers.\n Launching offer: one month free trial",
  canonicalLink: "https://fliqbook.in/features",
}

export const PRIVACY = {
  title: "Fliqbook - Amazon seller accounting | Amazon fba bookkeeping for Amazon sellers | Privacy policy",
  description: "India's First And Only Dedicated Software For Bookkeeping, Accounting & GST Filing For Amazon Sellers.\n Privacy Policy for Fliqbook",
  canonicalLink: "https://fliqbook.in/privacy",
}

export const TERMS = {
  title: "Fliqbook - Amazon seller accounting | Amazon fba bookkeeping for Amazon sellers | Terms of use",
  description: "India's First And Only Dedicated Software For Bookkeeping, Accounting & GST Filing For Amazon Sellers.\n THIS IS AN AGREEMENT BETWEEN YOU OR THE ENTITY THAT YOU REPRESENT (HEREINAFTER “YOU” or “YOUR”) AND FLIQBOOK AUTOMATION PVT. LTD. (HEREINAFTER “FLIQBOOK”) GOVERNING YOUR USE OF FLIQBOOK SUITE OF ONLINE ACCOUNTING AUTOMATION SOFTWARE IF YOU ARE BASED IN INDIA.",
  canonicalLink: "https://fliqbook.in/terms_of_use",
}

export const NOT_FOUND = {
  title: "404 | Fliqbook - Amazon seller accounting | Amazon fba bookkeeping for Amazon sellers",
  description: "India's First And Only Dedicated Software For Bookkeeping, Accounting & GST Filing For Amazon Sellers.\n Launching offer: one month free trial",
  canonicalLink: "https://fliqbook.in/404",
}
