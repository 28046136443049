import React from 'react';
import Check from '../svg/check50';
import './style.scss';

function List(props) {
  return <div className='data-list'>{props.data.map(d => <div key={d.text}>
    <Check />
    <span>{d.text}</span>
  </div>)}</div>;
}

export default List;
