import './index.scss';

const Modal = ({ isOpened, children }) => {
  return (
    <div aria-hidden="true" className={`${isOpened && 'active'} modal-bg`}>
      <div className={`modal-wrapper`} >
        <div className="modal-main">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal;