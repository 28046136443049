import React, { useEffect, useContext } from 'react';
import Header from '../../organisms/Header';
import SectionWithImage from '../../organisms/SectionWithImage';
import SectionData from '../../molecules/section-data';
import './index.scss';
import ContactForm from '../../organisms/ContactForm';
import Footer from '../../organisms/Footer';
import { StateContext } from '../../../context/State';
import { HOME2 } from '../../../constant/seo';
import { Helmet } from 'react-helmet-async';
import homeImg1 from '../../atoms/images/svg/home1.svg';
import homeImg10 from '../../atoms/images/svg/home10.svg';
import homeImg3 from '../../atoms/images/svg/home3.svg';
import homeImg5 from '../../atoms/images/svg/home5.svg';
import homeImg9 from '../../atoms/images/svg/home9.svg';

function Home1() {
  const { updateVariant } = useContext(StateContext);

  useEffect(() => {
    updateVariant(1);
  }, [updateVariant])

  return (
    <div>
      <Helmet>
        <title>{HOME2.title}</title>
        <meta name="description"
          content={`${HOME2.description}`} />
        <meta property="og:title" content={`${HOME2.title}`} />
        <meta name="twitter:title" content={`${HOME2.title}`} />
        <meta property="og:description" content={`${HOME2.description}`} />
        <meta name="twitter:description" content={`${HOME2.description}`} />
        <link rel="canonical" href={`${HOME2.canonicalLink}`} />
      </Helmet>
      <Header page='home1' />

      <SectionWithImage image={<img className="home1-laptop" src={homeImg1} alt='Amazon MTR to GSTR1 in just a few clicks' />}>
        {/* style={{ marginTop: '-80px' }}  */}
        <SectionData
          //tagline='MAKE GST FILING FAST. SIMPLE. EASY'
          header='Amazon MTR to GSTR1 in just a few clicks'
          subheader='A FREE tool to convert your Amazon MTR into GSTR1-ready .csv or .json file quickly and accurately. Store, Calculate and Visualize your monthly Amazon sales & taxes too!'
          externalLink={{
            label: 'SIGNUP NOW!',
            href: 'https://app.fliqbook.in/signup?p=0'
          }}
        />
      </SectionWithImage>

      <SectionWithImage image={<img src={homeImg10} alt='Convert your Amazon MTR into GSTR-1' />} rtl={true}>
        <SectionData
          header='Convert your Amazon MTR into GSTR-1'
          description='Convert your Amazon MTR into GSTR1-ready .csv or .json file quickly and accurately. Customized for both the monthly as well as the quarterly filers!'
        /* link={{
           label: 'Read More',
           to: '/features/1'
         }}*/
        />
      </SectionWithImage>

      <SectionWithImage image={<img src={homeImg3} alt='Quick and easy process to get GST compatible files' />} backgroundColor='#E6F5EA'>
        <SectionData
          header='Quick and easy process to get GST compatible files'
          description='For .csv or .json file, all you need to do is to upload the Amazon MTR on FliqBook application and then click on the ‘download .csvs’ button to download b2b.csv, b2cs.csv and cdnr.csv files to be imported inside the GST offline tool. Otherwise, you can click on the ‘download .json’ button to download the returns.json file to be directly imported into GST’s online portal.'
        /* link={{
           label: 'Read More',
           to: '/features/2'
         }}*/
        />
      </SectionWithImage>

      <SectionWithImage image={<img src={homeImg5} alt='Get Results in less than 10 minutes!' />} rtl={true}>
        <SectionData
          header='Get Results in less than 10 minutes!'
          description='This completely replaces the inaccurate and slow manual process of converting Amazon MTR into the gst-ready format and then typing or copy-pasting individual entries for gstr1 filing. With Fliqbook, this entire process will take less than 10 minutes!'
        /* link={{
           label: 'Read More',
           to: '/features/3'
         }}*/
        />
      </SectionWithImage>


      <ContactForm image={<img src={homeImg9} alt='Contact us' />}></ContactForm>

      <Footer page='home1' />
    </div>
  );
}

export default Home1;
